type QuizQuestions = [
  {
    number: number
    question: string
    field: 'color' | 'mood' | 'dayOff' | 'ingredients' | 'intensity'
    options:
      | Array<{ value: string; image: string }>
      | {
          male: Array<{ value: string; image: string }>
          female: Array<{ value: string; image: string }>
        }
  }
]

export const quizQuestions = [
  {
    number: 1,
    question: 'Qual a sua cor favorita?',
    field: 'color',
    options: [
      {
        value: 'Preto',
        image: '/assets/quiz-perfume/avon-section-preto.png',
      },
      {
        value: 'Azul',
        image: '/assets/quiz-perfume/avon-section-azul.png',
      },
      {
        value: 'Amarelo',
        image: '/assets/quiz-perfume/avon-section-amarelo.png',
      },
      {
        value: 'Verde',
        image: '/assets/quiz-perfume/avon-section-verde.png',
      },
      {
        value: 'Lilás',
        image: '/assets/quiz-perfume/avon-section-lilas.png',
      },
    ],
  },
  {
    number: 2,
    question: 'Como está se sentindo hoje?',
    field: 'mood',
    options: {
      male: [
        {
          value: 'Poderoso e Elegante',
          image: '/assets/quiz-perfume/avon-section-poderoso.png',
        },
        {
          value: 'Determinado e Confiante',
          image: '/assets/quiz-perfume/avon-section-determinado.png',
        },
        {
          value: 'Animado',
          image: '/assets/quiz-perfume/avon-section-animado.png',
        },
        {
          value: 'Energizado',
          image: '/assets/quiz-perfume/avon-section-energizado.png',
        },
        {
          value: 'Relaxado',
          image: '/assets/quiz-perfume/avon-section-relaxado.png',
        },
      ],
      female: [
        {
          value: 'Poderosa e Elegante',
          image: '/assets/quiz-perfume/avon-section-fem-poderosa.png',
        },
        {
          value: 'Romântica e Sonhadora',
          image: '/assets/quiz-perfume/avon-section-fem-romantica.png',
        },
        {
          value: 'Determinada e Confiante',
          image: '/assets/quiz-perfume/avon-section-fem-confiante.png',
        },
        {
          value: 'Animada',
          image: '/assets/quiz-perfume/avon-section-fem-animada.png',
        },
        {
          value: 'Energizada',
          image: '/assets/quiz-perfume/avon-section-fem-energizada.png',
        },
        {
          value: 'Relaxada',
          image: '/assets/quiz-perfume/avon-section-fem-relaxada.png',
        },
      ],
    },
  },
  {
    number: 3,
    question: 'Para qual ocasião gostaria de usar o perfume?',
    field: 'dayOff',
    options: {
      male: [
        {
          value: 'Ir à uma balada',
          image: '/assets/quiz-perfume/avon-section-balada.png',
        },
        {
          value: 'Ler um livro',
          image: '/assets/quiz-perfume/avon-section-livro.png',
        },
        {
          value: 'Se divertir com os amigos',
          image: '/assets/quiz-perfume/avon-section-amigos.png',
        },
        {
          value: 'Praticar atividades ao ar livre',
          image: '/assets/quiz-perfume/avon-section-atividades.png',
        },
        {
          value: 'Ter um momento relaxante',
          image: '/assets/quiz-perfume/avon-section-relaxante.png',
        },
      ],
      female: [
        {
          value: 'Ir à uma balada',
          image: '/assets/quiz-perfume/avon-section-fem-balada.png',
        },
        {
          value: 'Ir ao cinema',
          image: '/assets/quiz-perfume/avon-section-fem-cinema.png',
        },
        {
          value: 'Ler um livro',
          image: '/assets/quiz-perfume/avon-section-fem-livro.png',
        },
        {
          value: 'Se divertir com os amigos',
          image: '/assets/quiz-perfume/avon-section-fem-amigos.png',
        },
        {
          value: 'Praticar atividades ao ar livre',
          image: '/assets/quiz-perfume/avon-section-fem-atividade.png',
        },
        {
          value: 'Ter um momento relaxante',
          image: '/assets/quiz-perfume/avon-section-fem-relaxante.png',
        },
      ],
    },
  },
  {
    number: 4,
    question: 'Qual sua família olfativa favorita?',
    field: 'ingredients',
    options: {
      male: [
        {
          value: 'Adocicado',
          image: '/assets/quiz-perfume/avon-section-adocicado.png',
        },
        {
          value: 'Madeira',
          image: '/assets/quiz-perfume/avon-section-madeira.png',
        },
        {
          value: 'Ervas',
          image: '/assets/quiz-perfume/avon-section-ervas.png',
        },
        {
          value: 'Cítrico',
          image: '/assets/quiz-perfume/avon-section-citrico.png',
        },
      ],
      female: [
        {
          value: 'Floral',
          image: '/assets/quiz-perfume/avon-section-fem-floral.png',
        },
        {
          value: 'Adocicado',
          image: '/assets/quiz-perfume/avon-section-adocicado.png',
        },
        {
          value: 'Madeira',
          image: '/assets/quiz-perfume/avon-section-madeira.png',
        },
        {
          value: 'Ervas',
          image: '/assets/quiz-perfume/avon-section-ervas.png',
        },
        {
          value: 'Cítrico',
          image: '/assets/quiz-perfume/avon-section-citrico.png',
        },
      ],
    },
  },
  {
    number: 5,
    question: 'Qual o nível de intensidade que você gosta em um perfume?',
    field: 'intensity',
    options: [
      {
        value: 'Leve',
        image: '/assets/quiz-perfume/avon-section-leve.png',
      },
      {
        value: 'Moderado',
        image: '/assets/quiz-perfume/avon-section-moderado.png',
      },
      {
        value: 'Intenso',
        image: '/assets/quiz-perfume/avon-section-intenso.png',
      },
    ],
  },
] as unknown as QuizQuestions
