import React from 'react'
import type { Dispatch, SetStateAction, ReactNode } from 'react'

import { ButtonsContainer } from './styled'

interface Props {
  question: number
  setQuestion: Dispatch<SetStateAction<number>>
  registerButton?: ReactNode
}

export const NavigationButtons = ({
  question,
  setQuestion,
  registerButton,
}: Props) => {
  const handleNavigationButtonClick = (action: 'next' | 'prev') => {
    action === 'next' ? setQuestion(question + 1) : setQuestion(question - 1)

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <ButtonsContainer isFormStep={!!registerButton}>
      {question > 1 && (
        <button
          className="prev"
          onClick={() => handleNavigationButtonClick('prev')}
        >
          Anterior
        </button>
      )}

      {question < 6 && (
        <button
          className="next"
          onClick={() => handleNavigationButtonClick('next')}
        >
          Próximo
        </button>
      )}

      {question === 6 && registerButton}
    </ButtonsContainer>
  )
}
