import React, { useState } from 'react'

import { Option, OptionsContainer, QuestionsContainer } from './styled'
import { quizQuestions } from './quizQuestion'
import { NavigationButtons } from './NavigationButtons'
import SendQuiz from './SendQuiz'

interface Props {
  gender: 'masculino' | 'feminino'
}

export interface Answers {
  color: number
  mood: number
  dayOff: number
  ingredients: number
  intensity: number
  name: string
  email: string
  gender: 'masculino' | 'feminino'
}

export default function QuizPerfumeQuestions({ gender }: Props) {
  const [question, setQuestion] = useState(1)
  const [answers, setAnswers] = useState<Answers>({
    color: 0,
    mood: 0,
    dayOff: 0,
    ingredients: 0,
    intensity: 0,
    name: '',
    email: '',
    gender,
  })

  if (question === 6) {
    return (
      <SendQuiz
        question={question}
        setQuestion={setQuestion}
        answers={answers}
        setAnswers={setAnswers}
      />
    )
  }

  const currentQuestion = quizQuestions[question - 1]
  const currentOptions =
    'male' in currentQuestion.options
      ? currentQuestion.options[gender === 'masculino' ? 'male' : 'female']
      : currentQuestion.options

  const currentQuestionAnswer = answers[currentQuestion.field]

  return (
    <QuestionsContainer>
      <h1>
        {currentQuestion.number}. {currentQuestion.question}
      </h1>

      <OptionsContainer isOptionSelected={currentQuestionAnswer !== 0}>
        {currentOptions.map((option, index) => (
          <Option
            key={option.value}
            className={currentQuestionAnswer === index + 1 ? 'active' : ''}
            onClick={() =>
              setAnswers({ ...answers, [currentQuestion.field]: index + 1 })
            }
          >
            <img src={option.image} alt={option.value} />
            <p>{option.value}</p>
          </Option>
        ))}
      </OptionsContainer>

      <NavigationButtons question={question} setQuestion={setQuestion} />
    </QuestionsContainer>
  )
}
