import { styled } from 'src/gatsby-theme-stitches/config'

export const QuestionsContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2.5rem',
  fontFamily: 'Lato',
  py: '5rem',

  '@sm': {
    py: '2.5rem',
  },

  h1: {
    fontSize: '1.5rem',
    textAlign: 'center',

    '@sm': {
      px: '.75rem',
    },
  },
})

export const OptionsContainer = styled('div', {
  display: 'flex',
  gap: '2.5rem 5rem',
  flexWrap: 'wrap',
  justifyContent: 'center',

  '@sm': {
    flexDirection: 'column',
  },

  variants: {
    isOptionSelected: {
      true: {
        '> div': {
          opacity: '0.5',

          '&.active': {
            opacity: '1',
          },
        },
      },
    },
  },
})

export const Option = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  cursor: 'pointer',

  p: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
})

export const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: '1.5rem',
  width: '100%',

  '@sm': {
    px: '1.25rem',
  },

  button: {
    width: '11rem',
    height: '3.5rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Lato',
    borderTopLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    cursor: 'pointer',
    transition: '0.4s',

    '&.next': {
      border: 'none',
      color: '$onPrimary',
      backgroundColor: '$highlight',

      '&:hover': {
        border: '1px solid $colors$highlight',
        color: '$highlight',
        backgroundColor: '$onPrimary',
      },
    },

    '&.prev': {
      backgroundColor: 'transparent',
      border: '1px solid $colors$primary',
      color: '$primary',

      '&:hover': {
        color: '$onPrimary',
        backgroundColor: '$primary',
      },
    },
  },

  variants: {
    isFormStep: {
      true: {
        justifyContent: 'flex-start',
      },
    },
  },
})

export const SendQuizContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: '2.5rem',
  py: '4rem',
  fontFamily: 'Lato',

  '@sm': {
    flexDirection: 'column',

    img: {
      maxWidth: '90%',
      mx: 'auto',
    },
  },
})

export const FormContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',

  '@sm': {
    maxWidth: '90%',
    mx: 'auto',
  },

  h4: {
    fontSize: '2.25rem',

    '@sm': {
      fontSize: '1.5rem',
      textAlign: 'center',
    },
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',

    input: {
      width: '100%',
      height: '4rem',
      fontSize: '1.125rem',
      paddingLeft: '1.125rem',
      border: '1px solid $colors$lowEmphasis',
      borderRadius: '8px',
      fontFamily: 'Zona Pro, sans-serif',
      maxWidth: '27.5rem',
      outline: 'none',

      '@sm': {
        height: '2.25rem',
      },
    },

    div: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontFamily: 'Zona Pro, sans-serif',
      fontSize: '1.25rem',
      fontWeight: 'bold',

      '@sm': {
        fontSize: '.875rem',
      },

      span: {
        size: '1.5rem',
        backgroundColor: 'transparent',
        border: '1px solid $colors$lowEmphasis',
        borderRadius: '50%',
        display: 'grid',
        placeItems: 'center',

        '&.checked': {
          backgroundColor: '$highlight',
          borderColor: '$highlight',

          '&:after': {
            content: '"✔"',
            fontSize: '.875rem',
            color: '$onPrimary',
            fontWeight: 'bold',
          },
        },
      },
    },

    '.error': {
      fontSize: '1rem',
      fontFamily: 'Zona Pro, sans-serif',
      color: '$primary',
    },
  },
})
