import React from 'react'
import type { PageProps } from 'gatsby'
import type { FC } from 'react'
import Layout from 'src/components/Layout'
import QuizPerfumeQuestions from 'src/components/quiz/perfume/Questions'
import { Helmet } from 'react-helmet'

type Props = PageProps & {
  gender: 'masculino' | 'feminino'
}

const Page: FC<Props> = ({ gender }) => {
  return (
    <>
      <Helmet>
        <title>Quiz Perfume | {gender} | Avon</title>
      </Helmet>

      <Layout>
        <QuizPerfumeQuestions gender={gender} />
      </Layout>
    </>
  )
}

export default Page
