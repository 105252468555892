import React, { useEffect, useState, useRef } from 'react'
import type { Dispatch, SetStateAction, FormEvent } from 'react'
import { useProfile } from '@vtex/gatsby-theme-store'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'
import { navigate } from 'gatsby'

import { NavigationButtons } from './NavigationButtons'
import { FormContainer, SendQuizContainer } from './styled'
import type { Answers } from '.'

interface Props {
  question: number
  setQuestion: Dispatch<SetStateAction<number>>
  answers: Answers
  setAnswers: Dispatch<SetStateAction<Answers>>
}

export default function SendQuiz({
  question,
  setQuestion,
  answers,
  setAnswers,
}: Props) {
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [hasError, setHasError] = useState(false)
  const inputEmailRef = useRef<HTMLInputElement>(null)

  const profile = useProfile()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'
  const userEmail = profile?.email?.value

  const { setIsLoginModalOpen } = useLoginModal()

  useEffect(() => {
    if (isAuthenticated) {
      inputEmailRef.current.value = profile?.email?.value
    } else {
      setIsLoginModalOpen(true)
    }
  }, [isAuthenticated, profile?.email?.value, setIsLoginModalOpen, userEmail])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!acceptTerms) {
      return setHasError(true)
    }

    localStorage.setItem('quizPerfumes-answers', JSON.stringify(answers))

    const documentId = await fetch(
      `/api/io/safedata/CL/search?_where=email=${answers.email}&_fields=id`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((data: Array<{ id: string }>) => data[0].id)

    await fetch(`/api/io/safedata/PQ/documents/${documentId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailCliente: answers.email,
        nomeCliente: answers.name,
      }),
    })

    const gender = answers.gender === 'masculino' ? '1' : '2'

    const res = await fetch(
      `/api/io/safedata/PF/search?_where=genero=${gender} AND cor=${answers.color} AND mood=${answers.mood} AND day_off=${answers.dayOff} AND ingredientes=${answers.ingredients} AND intensidade=${answers.intensity}&_fields=produto`
    )

    const [result] = await res.json()

    navigate(
      `/quiz-perfume/${answers.gender}/resultado?productId=${result.produto}`
    )
  }

  return (
    <SendQuizContainer>
      <img
        src="/assets/quiz-perfume/perfumeFinderRegister-Desk.png"
        alt="Mulher de vestido vermelho segurando um perfume"
      />

      <FormContainer>
        <h4>Confira o seu resultado</h4>

        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="E-mail"
            onChange={(e) => setAnswers({ ...answers, email: e.target.value })}
            ref={inputEmailRef}
            disabled={!!isAuthenticated}
            required
          />
          <input
            type="text"
            placeholder="Nome"
            onChange={(e) => setAnswers({ ...answers, name: e.target.value })}
            required
          />

          <div onClick={() => setAcceptTerms(!acceptTerms)} role="button">
            <span className={`checkbox ${acceptTerms ? 'checked' : ''}`} />
            Aceito os termos de política de privacidade
          </div>

          {hasError && (
            <span className="error">
              É necessário aceitar os termos de privacidade
            </span>
          )}

          <NavigationButtons
            question={question}
            setQuestion={setQuestion}
            registerButton={
              <button className="next" type="submit">
                Cadastre-se
              </button>
            }
          />
        </form>
      </FormContainer>
    </SendQuizContainer>
  )
}
